import { default as React } from 'react';

const NewLineAddedText = ({ text, className }: { text?: string; className?: string }) => {
	if (!text) return null;
	return (
		<>
			{text.split('\n').map((txt, index) => (
				<span key={index}>
					{txt}
					<br />
				</span>
			))}
		</>
	);
};
export default NewLineAddedText;
