import { css } from '@emotion/react';
import styled from '@emotion/styled';
import axios from 'axios';
import { default as React, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import downloadIcon from '../../assets/icons/icon_file_download.svg';
import jpgIcon from '../../assets/icons/icon_file_jpg.png';
import pdfIcon from '../../assets/icons/icon_file_pdf.png';
import pngIcon from '../../assets/icons/icon_file_png.png';
import otherIcon from '../../assets/icons/icon_file_unknown.png';
import { BLACK } from '../../constants/colors';
import { getDesktopStyle } from '../../styles/layout';
import { AttactchedFileType } from '../../types/common';
import { formatBytes, getFileType, pxToRem } from '../../utils/common';

const getFileIcon = (type: string) => {
	switch (type) {
		case 'PNG':
			return pngIcon;
		case 'JPG' || 'JPEG':
			return jpgIcon;
		case 'PDF':
			return pdfIcon;
		default:
			return otherIcon;
	}
};

// 파일을 GET 후 bolb 형태로 파일을 다운로드하는 컴포넌트
const Download = ({ url, filename, children }: { url: string; filename: string; children: ReactNode }) => {
	const [fetching, setFetching] = useState(false);
	const [error, setError] = useState(false);

	const download = useCallback((url: string, name: string) => {
		if (!url) {
			alert('파일을 다운로드 가능한 주소가 없습니다. 담당자에게 문의 바랍니다.');
			return;
		}
		setFetching(true);
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				setFetching(false);
				const blobURL = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = blobURL;
				a.style.display = 'none';

				if (name && name.length) a.download = name;
				document.body.appendChild(a);
				a.click();
			})
			.catch(() => {
				alert('파일을 다운로드 할 수 없습니다. 담당자에게 문의 바랍니다.');
				setError(true);
			});
	}, []);

	return (
		<FileLink disabled={fetching} onClick={() => download(url, filename)} aria-label="download">
			{children}
		</FileLink>
	);
};

const FileLink = styled.button`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: ${pxToRem(12)} ${pxToRem(14)};
	font-weight: 300;
	text-align: left;

	${getDesktopStyle(css`
		padding: ${pxToRem(20)} ${pxToRem(43)} ${pxToRem(20)} ${pxToRem(24)};
	`)}
`;

const FileItem = ({ itemData }: { itemData: AttactchedFileType }) => {
	const [fileSize, setFileSize] = useState<string>('0KB');
	const { fileUrl, fileName } = itemData;
	const fileType = useMemo(() => getFileType(fileUrl), [fileUrl]);
	const fileIcon = useMemo(() => getFileIcon(fileType), [fileType]);

	useEffect(() => {
		// 파일 사이즈와 파일 타입 얻기
		const getFileTypeAndSize = async (link: string) => {
			try {
				const { headers } = await axios.head(link);
				const contentLength = headers['content-length'];
				setFileSize(formatBytes(parseInt(contentLength)));
			} catch (error) {
				console.log(error);
			}
		};
		getFileTypeAndSize(fileUrl);
	}, [fileUrl]);

	return (
		<File>
			<Download url={fileUrl} filename={fileName}>
				<FileInfoWrapper>
					<FileIcon src={fileIcon} alt="" />
					<div>
						<p>{fileName}</p>
						<FileInfo>
							{fileType} 다운로드. {fileSize}
						</FileInfo>
					</div>
				</FileInfoWrapper>
				<DownloadIcon src={downloadIcon} alt="" />
			</Download>
		</File>
	);
};

export default FileItem;

const DownloadIcon = styled.img`
	width: ${pxToRem(11)};
	height: ${pxToRem(16)};

	${getDesktopStyle(css`
		width: ${pxToRem(13)};
		height: ${pxToRem(18)};
	`)}
`;

const FileIcon = styled.img`
	width: ${pxToRem(20.6)};
	height: ${pxToRem(28)};
	margin-right: ${pxToRem(11)};

	${getDesktopStyle(css`
		width: ${pxToRem(28)};
		height: ${pxToRem(38)};
		margin-right: ${pxToRem(17)};
	`)}
`;

const FileInfo = styled.p`
	font-size: ${pxToRem(11)};
	color: #737373;
	font-weight: 400;

	${getDesktopStyle(css`
		font-size: ${pxToRem(14)};
	`)}
`;

const FileInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	font-weight: 400;
`;

const File = styled.li`
	border: 0.5px solid ${BLACK};
	border-radius: 2px;
	font-size: ${pxToRem(11)};

	${getDesktopStyle(css`
		border: 1px solid ${BLACK};
		font-size: ${pxToRem(16)};
	`)}
`;
