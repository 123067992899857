import { AttactchedFileType, BooleanType, ListResposeDataType, ResposeDataType } from '../types/common';
import { BASE_URL, HTTP } from '../utils/http';

export interface AnnouncmentSummaryType {
	noticeId: number;
	title: string;
	noticeDate: string; // 실제 공고일
	createdAt: string; // 2020.01.01
}

export interface AnnouncementListData {
	noticeId: number; // 공고 ID
	title: string; // 제목
	content: string; // 내용
	createdAt: string; // 등록일
	noticeDate: string; // 실제 공고일
}

export interface AnnouncmentData {
	title: string;
	content: string;
	highlightYn: BooleanType;
	noticeFiles: AttactchedFileType[];
	viewCount: number;
	createdAt: string; // 2022-03-14 18:15
	noticeDate: string; // 실제 공고일
	previousNotice: AnnouncmentSummaryType;
	nextNotice: AnnouncmentSummaryType;
}

const ANNOUNCEMENT_URL = '/api/v3/notices';

export const AnnouncementApi = {
	getAnnouncementList: async () => {
		const response = await HTTP.get<ListResposeDataType<AnnouncementListData[]>>(
			`${BASE_URL}${ANNOUNCEMENT_URL}/ir?page=0&size=100`,
		);
		return response.data;
	},
	getAnnouncement: async (id: string) => {
		const response = await HTTP.get<ResposeDataType<AnnouncmentData>>(`${BASE_URL}${ANNOUNCEMENT_URL}/${id}/ir`);
		return response.data;
	},
};
