import axios from 'axios';
import { AnnouncementApi, AnnouncementListData, AnnouncmentData } from '../api/Announcement';

class AnnouncementServices {
	static async processGetAnnouncmentList({
		successCallback,
		errorCallback,
	}: {
		successCallback: (data: AnnouncementListData[]) => void;
		errorCallback: () => void;
	}) {
		try {
			const response = await AnnouncementApi.getAnnouncementList();
			if (response.code === 'SUCCESS') {
				successCallback(response.resultData.content);
			} else {
				errorCallback();
			}
		} catch (e: any) {
			if (axios.isAxiosError(e) && e.response) {
				alert(e.response?.data?.message || '문제가 발생했습니다. 고객센터에 문의해주세요.');
			} else {
				alert('문제가 발생했습니다. 고객센터에 문의해주세요.');
			}
		}
	}

	static async processGetAnnouncment({
		params,
		successCallback,
		errorCallback,
	}: {
		params: string;
		successCallback: (data: AnnouncmentData) => void;
		errorCallback: () => void;
	}) {
		try {
			const response = await AnnouncementApi.getAnnouncement(params);
			if (response.code === 'SUCCESS') {
				successCallback(response.resultData);
			} else {
				errorCallback();
			}
		} catch (e: any) {
			if (axios.isAxiosError(e) && e.response) {
				alert(e.response?.data?.message || '문제가 발생했습니다. 고객센터에 문의해주세요.');
			} else {
				alert('문제가 발생했습니다. 고객센터에 문의해주세요.');
			}
		}
	}
}

export default AnnouncementServices;
